<template>
  <div>
    <div v-if="debug">
      mData:{{mData}}
      <hr />
      getLegendData:{{getLegendData}}
    </div>
    <div ref="echartComp" style="width: 100%; height: 100%"></div>
  </div>
</template>

<script>
import echarts from "echarts";

export default {
  props: {
    propData: {
      default() {
        return [];
        // [
        //   { value: 35, name: "作业超速报警（35,10%）" },
        //   { value: 31, name: "清洗作业晚点（31,10%）" },
        //   { value: 23, name: "清扫作业晚点（23,10%）" },
        //   { value: 13, name: "作业里程不足（13,10%）" }
        // ]
      },
      type: Array
    }
  },
  data() {
    return {
      debug: 0,
      mData: this.propData,
      echartComp: null,
      options: {
        tooltip: {
          trigger: "item",
          formatter: "{b} ({c}，{d}%)",
          backgroundColor: "rgba(3, 94, 255, 0.5)"
        },
        legend: {
          orient: "vertical",
          right: 1,
          padding: [5, 5, 5, 5],
          textStyle: {
            color: "#ffffff"
          },
          icon: "circle",
          data: [],
        },
        color: ["#EC3022", "#0E6DE9","#E6AF08", "#06BF79"],
        backgroudColor:"#fff",
        series: [
          {
            name: "安全报警分析",
            type: "pie",
            radius: ["26%", "80%"],
            roseType:"area",
            animation:true,
            animationTypeUpdate:"transition",
            avoidLabelOverlap: true,            
            emphasis: {
              label: {
                show: false,
              }
            },
            label: {
              show: true,
              position:"outer",
              alignTo :"none",
                formatter(v){
                  let text = /*v.name.replace("报警","") +*/ v.value+"，"+v.percent+"%";
                  return text;
                }
            },
            labelLine: {
              show: true,
              length:0,
              length2:8
            },
            itemStyle: {
              borderWidth: 0,
              borderColor: "rgb(5,10,40,0.9)",
              borderType: "solid"
            },
            center: ["30%", "50%"],
            data: []
          }
        ]
      }
    };
  },
  watch: {
    propData: {
      handler(v, ov) {
        this.log("aylRing", "propData changed", v);
        this.mData = v;
        this.initEchartComp();
      },
      deep: true
      // immediate: true
    }
  },
  computed: {
    getLegendData() {
      let self = this;
      let d = [];
      if (self.propData && Array.isArray(self.propData))
        d = [].concat(
          self.propData.map(item => {
            return item.name;
          })
        );
      return d;
    }
  },
  methods: {
    initEchartComp() {
      let self = this;
      if (!self.echartComp) {
        self.echartComp = echarts.init(self.$refs.echartComp);
        let resizeFunc = function() {
          self.echartComp.resize();
        };

        window.addEventListener("resize", resizeFunc);
        self.$once("hook:beforeDestroy", function() {
          window.removeEventListener("resize", resizeFunc);
          self.echartComp.dispose();
          self.echartComp = null;
        });
      }
      self.options.legend.data = self.getLegendData;
      self.options.series[0].data = self.$_.cloneDeep(self.mData);
      self.log(self.options);
      self.echartComp.setOption(self.options);
    }
  },
  mounted() {
    this.log("aylRing", "mounted");
    this.initEchartComp();
  }
};
</script>

<style lang='sass' scoped>
</style>
